// services/apiService.ts
/**
 * Configures and creates an Axios instance for API calls.
 * Includes interceptors to handle responses and errors globally.
 *
 * @module ApiService
 */
import { redirectToLogin } from "@/utils/utils";
import axios, { AxiosResponse } from "axios";
import { Router } from "vue-router";

// Instance of the router, initially set to null.
const routerInstance: Router | null = null;

// Creates the Axios instance with basic configuration.
const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL || "http://localhost",
  withCredentials: true,
});

console.log(`API URL ${process.env.VUE_APP_API_URL}`);

// Response interceptor to handle token renewal and redirections.
api.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const originalRequest = error.config;
    // Handles 403 error and attempts token renewal.
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        console.log("Refrescando token");
        await api.post("/auth/refresh-token");
        return api(originalRequest);
      } catch (refreshError) {
        // Redirects to login on failure.
        if (routerInstance) {
          redirectToLogin(routerInstance);
        }
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
