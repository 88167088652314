<template>
  <v-chip
    class="ma-1"
    :color="chipColor"
    density="default"
    elevation="1"
    label
    size="x-small"
    text-color="white"
    @click.stop="emitStatusChanged"
  >
    {{ chipText }}
  </v-chip>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  activo: {
    type: Boolean,
    default: true,
  },
  txtTrue: {
    type: String,
    default: "Activo",
  },
  txtFalse: {
    type: String,
    default: "Inactivo",
  },
  colTrue: {
    type: String,
    default: "success",
  },
  colFalse: {
    type: String,
    default: "error",
  },
});

const emits = defineEmits(["statusChanged"]);

const chipColor = computed(() => {
  return props.activo ? props.colTrue : props.colFalse;
});

const chipText = computed(() => {
  return props.activo ? props.txtTrue : props.txtFalse;
});

const emitStatusChanged = () => {
  emits("statusChanged");
};
</script>
