import { Router } from "vue-router";

/**
 * Utility functions module providing common functionalities across the application.
 * Includes functions for playing notification sounds and redirecting to the login page.
 *
 * @module utils
 */

/**
 * Plays a notification sound.
 * Attempts to play an audio file specified by the path. Logs an error to the console if it fails.
 *
 * @example
 * import { playNotificationSound } from './utils';
 * playNotificationSound(); // Plays the notification sound.
 */
export function playNotificationSound(): void {
  const audio = new Audio("/sounds/notification3.wav");
  audio
    .play()
    .catch((e: Error) =>
      console.error("Error al reproducir el sonido de notificación:", e)
    );
}

/**
 * Redirects the user to the login page.
 * Uses the Vue Router instance to navigate to the '/login' route.
 *
 * @param {Router} router - The Vue Router instance.
 * @example
 * import { redirectToLogin } from './utils';
 * // Assuming `router` is the Vue Router instance
 * redirectToLogin(router); // Redirects to the login page.
 */
export function redirectToLogin(router: Router): void {
  router.push("/login");
}
