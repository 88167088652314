/**
 * Defines application routes.
 * @module routes
 */
import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("@/layouts/default/DefaultLayout.vue"),
    children: [
      {
        path: "",
        name: "Home",
        // route level code-splitting
        // this generates a separate chunk (Home-[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/ConsoleView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/airports",
        component: () => import("@/modules/Airports.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/deductions",
        component: () => import("@/modules/Deductions.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/hotels",
        component: () => import("@/modules/Hotels.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/rates",
        component: () => import("@/modules/Rates.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/vehicle-types",
        component: () => import("@/modules/VehicleTypes.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/zones",
        component: () => import("@/modules/Zones.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/LoginPage.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    component: () => import("@/pages/ErrorNotFound.vue"),
  },
];

export default routes;
