// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { es } from "vuetify/locale";

// Vuetify
import { createVuetify } from "vuetify";

const darkTheme = {
  dark: true,
  colors: {
    background: "#121212", // fondo oscuro
    surface: "#1E1E1E", // superficie de elementos como tarjetas, menús
    primary: "#01579B", // color primario
    secondary: "#03DAC6", // color secundario
    error: "#CF6679", // color para errores
    info: "#2196F3", // color informativo
    success: "#4CAF50", // color para éxitos o confirmaciones
    warning: "#FB8C00", // color para advertencias
    // Puedes agregar más colores personalizados aquí
  },
};

const lightTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF", // fondo claro
    surface: "#F5F5F5", // superficie de elementos como tarjetas, menús
    primary: "#1976D2", // color primario
    secondary: "#424242", // color secundario
    error: "#FF5252", // color para errores
    info: "#2196F3", // color informativo
    success: "#4CAF50", // color para éxitos o confirmaciones
    warning: "#FFC107", // color para advertencias
    // Puedes agregar más colores personalizados aquí
  },
};

export default createVuetify({
  locale: {
    locale: "es",
    fallback: "es",
    messages: { es },
  },
  theme: {
    defaultTheme: "light", // tema por defecto
    themes: {
      light: lightTheme,
      dark: darkTheme,
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
