import { App } from "vue";
// Importa explícitamente cada componente base
import BaseMaterialCard from "@/components/base/MaterialCard.vue";
import BaseConfirmDialog from "@/components/base/ConfirmDialog.vue";
import BaseStatus from "@/components/base/StatusInfo.vue";
import BaseBreadcrumb from "@/components/base/CustomBreadcrumb.vue";
import BaseDatePicker from "@/components/base/CustomDatePicker.vue";
// Continúa importando otros componentes base...

export default {
  install(app: App): void {
    // Registra cada componente importado
    app.component("BaseMaterialCard", BaseMaterialCard);
    app.component("BaseConfirmDialog", BaseConfirmDialog);
    app.component("BaseStatus", BaseStatus);
    app.component("BaseBreadcrumb", BaseBreadcrumb);
    app.component("BaseDatePicker", BaseDatePicker);
  },
};
