<template>
  <v-card :class="['v-card--material', 'pa-3', classes]" v-bind="$attrs">
    <div class="d-flex grow flex-wrap">
      <v-avatar
        v-if="avatar"
        size="128"
        class="mx-auto v-card--material__avatar elevation-6"
        color="grey"
      >
        <v-img :src="avatar" />
      </v-avatar>
      <v-sheet
        v-else
        :color="color"
        :height="icon ? '90px' : undefined"
        :width="inline || icon ? '90px' : '100%'"
        elevation="6"
        class="v-card--material__heading align-center mb-n6 d-flex justify-center"
        dark
        rounded
      >
        <template v-if="$slots.heading">
          <slot name="heading" />
        </template>
        <template v-else-if="$slots.image">
          <slot name="image" />
        </template>
        <div v-else-if="title && !icon" class="display-1 font-weight-light">
          {{ title }}
        </div>
        <v-icon v-else-if="icon" size="32">{{ icon }}</v-icon>
        <div v-if="text" class="headline font-weight-thin">{{ text }}</div>
      </v-sheet>
      <div v-if="$slots['after-heading']" class="ml-6">
        <slot name="after-heading" />
      </div>
      <v-col v-if="hoverReveal" cols="12" class="text-center py-0 mt-n12">
        <slot name="reveal-actions" />
      </v-col>
      <div v-else-if="icon && title" class="ml-4">
        <div class="card-title font-weight-light">{{ title }}</div>
      </div>
      <v-spacer></v-spacer>
      <div v-if="$slots['corner-button']">
        <slot name="corner-button" />
      </div>
    </div>
    <slot />
    <template v-if="$slots.actions">
      <v-divider class="mt-2" />
      <v-card-actions class="pb-0">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  avatar: String,
  color: { type: String, default: "success" },
  hoverReveal: Boolean,
  icon: String,
  image: Boolean,
  inline: Boolean,
  text: String,
  title: String,
  button: String,
});

const classes = computed(() => ({
  "v-card--material--has-heading": hasHeading.value,
  "v-card--material--hover-reveal": props.hoverReveal,
}));

const hasHeading = computed(() => Boolean(props.title || props.icon));
</script>

<style lang="sass">
.v-card--material
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative
    top: 0px
    transition: .3s ease
    z-index: 1

  &.v-card--material--hover-reveal:hover
    .v-card--material__heading
      transform: translateY(-40px)
</style>
