/**
 * @fileoverview Defines the Pinia store for authentication management. This store includes state management
 * for user authentication and actions to login, logout, and verify user sessions using designated authentication services.
 */

// store/auth.ts
import { defineStore } from "pinia";
import { login, logout, verify } from "@/services/authService";
import { User } from "@/types";
import { ref } from "vue";

/**
 * Defines and exports the auth store using Pinia.
 */
export const useAuthStore = defineStore("auth", {
  state: () => ({
    isAuthenticated: false, // State to track if the user is authenticated.
    user: ref<User | null>(null), // State to store the authenticated user's information.
    isVerifying: true, // State to track the verification status of the user session.
  }),
  actions: {
    /**
     * Attempts to log in a user with the provided credentials.
     * @param {string} email - The email of the user.
     * @param {string} password - The password of the user.
     * @returns {Promise<void>}
     */
    async loginUser(email: string, password: string): Promise<void> {
      try {
        const response = await login(email, password);
        if (response && response._id) {
          this.user = {
            _id: response._id,
            email: response.email,
          };
          this.isAuthenticated = true;
        }
      } catch (error) {
        console.error("Error en el login:", error);
      }
    },

    /**
     * Logs out the current user.
     * @returns {Promise<void>}
     */
    async logoutUser(): Promise<void> {
      try {
        await logout();
      } finally {
        this.user = null;
        this.isAuthenticated = false;
      }
    },

    /**
     * Verifies the current user session and updates the authentication state.
     * @returns {Promise<void>}
     */
    async verifySession(): Promise<void> {
      this.isVerifying = true;
      try {
        const response = await verify();
        if (response.isAuthenticated) {
          this.user = response.user;
          this.isAuthenticated = true;
        } else {
          this.user = null;
          this.isAuthenticated = false;
        }
      } catch (error) {
        this.user = null;
        this.isAuthenticated = false;
      } finally {
        this.isVerifying = false;
      }
    },
  },
});
