/**
 * Configures and creates the Vue router.
 * @module router
 */

import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { useAuthStore } from "@/store/auth";

const router = createRouter({
  scrollBehavior: () => ({ left: 0, top: 0 }),
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/**
 * Global route guard to handle authentication for protected routes.
 */
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  if (!authStore.isAuthenticated) {
    await authStore.verifySession();
  }
  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    next({ path: "/login" });
  } else {
    next();
  }
});

export default router;
