<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="text-h5">{{ title }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="closeDialog(false)">Cancelar</v-btn>
        <v-btn color="success" @click="closeDialog(true)">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";

/**
 * Props
 */
defineProps({
  title: {
    type: String,
    default: "Confirmar",
  },
  text: {
    type: String,
    default: "Esta acción no puede revertirse",
  },
});

/**
 * State
 */
const dialog = ref(false);
let resolvePromise: (value: boolean) => void;

/**
 * Opens the confirmation dialog.
 * @returns {Promise<boolean>} A promise that resolves to the user's choice.
 */
const openDialog = () => {
  dialog.value = true;
  return new Promise<boolean>((resolve) => {
    resolvePromise = resolve;
  });
};

/**
 * Closes the dialog and resolves the promise with the user's choice.
 * @param {boolean} result - The user's choice.
 */
const closeDialog = (result: boolean) => {
  dialog.value = false;
  resolvePromise(result);
};

/**
 * Expose the function to open the dialog
 */
defineExpose({
  openDialog,
});
</script>
