// src/features/auth/services/authService.ts
import api from "./apiService";
import { User } from "@/types";
const ENDPOINT = "/auth";

/**
 * AuthService module provides functions for handling authentication-related operations.
 * @module AuthService
 */

/**
 * Logs in a user using their email and password.
 * Makes a POST request to the '/login' endpoint and returns the user data.
 *
 * @param {string} email - The email of the user.
 * @param {string} password - The password of the user.
 * @returns {Promise<User>} A promise that resolves to the user data.
 * @throws {Error} Throws an error if the login request fails.
 */
export const login = async (email: string, password: string): Promise<User> => {
  try {
    const response = await api.post(ENDPOINT + "/login", { email, password });
    return response.data.user;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message || "Error al iniciar sesión.");
    } else {
      throw new Error("Error al iniciar sesión.");
    }
  }
};

/**
 * Logs out the current user.
 * Makes a POST request to the '/logout' endpoint.
 *
 * @returns {Promise<{ message: string }>} A promise that resolves when the user is logged out.
 * @throws {Error} Throws an error if the logout request fails.
 */
export const logout = async (): Promise<{ message: string }> => {
  try {
    const response = await api.post(ENDPOINT + "/logout");
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message || "Error al cerrar sesión.");
    } else {
      throw new Error("Error al cerrar sesión.");
    }
  }
};

/**
 * Verifies the current user's authentication status.
 * Makes a GET request to the '/verify' endpoint.
 *
 * @returns {Promise<any>} A promise that resolves to the verification data.
 * @throws {Error} Throws an error if the verification request fails.
 */
export const verify = async () => {
  console.log("llamando api verifica");
  try {
    const response = await api.get(ENDPOINT + "/verify");
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message || "Error al verificar autenticacion.");
    } else {
      throw new Error("Error al verificar autenticación.");
    }
  }
};
