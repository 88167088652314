<template>
  <!-- Date picker menu component using Vuetify -->
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <!-- Activator slot for date picker input field -->
    <template v-slot:activator="{ props }">
      <v-text-field
        density="compact"
        :error-messages="errorMessages"
        :label="label"
        readonly
        variant="underlined"
        v-bind="props"
        v-model="dateString"
        @blur="() => emit('blur')"
        @change="(event: InputEvent) => emit('change', (event.target as HTMLInputElement).value)"
        @input="(event: InputEvent) => emit('input', (event.target as HTMLInputElement).value)"
      />
    </template>
    <!-- Date picker component -->
    <v-date-picker
      color="primary"
      elevation="24"
      hide-header
      show-adjacent-months
      :title="title"
      v-model="date"
      @update:model-value="handleDateChange"
    ></v-date-picker>
  </v-menu>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import moment from "moment-timezone";

// Define props with type annotations
interface Props {
  modelValue: Date | string | null;
  label: string;
  title: string;
  errorMessages?: string | string[];
  endOfDay?: boolean;
}

// Setting default props values
const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  errorMessages: "",
  endOfDay: false,
});

// Define component emits
const emit = defineEmits(["update:modelValue", "blur", "change", "input"]);

// Reactive state for menu visibility and selected date
const menu = ref(false);
const date = ref(props.modelValue ? new Date(props.modelValue) : null);
const dateString = ref("");

// Watches the date selection and updates the dateString accordingly
watch(date, (newValue) => {
  dateString.value = newValue
    ? moment(newValue).tz("America/Cancun").format("YYYY-MM-DD")
    : "";
  const adjustedDate = props.endOfDay
    ? moment.tz(newValue, "America/Cancun").endOf("day")
    : moment.tz(newValue, "America/Cancun").startOf("day");
  emit("update:modelValue", adjustedDate.format());
});

// Handles date changes from the picker
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleDateChange = (newValue: any) => {
  date.value = newValue; // Actualiza la fecha basada en la selección del usuario
  menu.value = false; // Cierra el menú
};

// Formats a Date object into 'YYYY-MM-DD' format
function formatDate(date: Date): string {
  return moment(date).tz("America/Cancun").format("YYYY-MM-DD");
}

// Initialize dateString with the provided modelValue, if exist
dateString.value = props.modelValue
  ? formatDate(new Date(props.modelValue))
  : "";
</script>
